<!-- <template>
  <v-list-item>
    <v-list-item-title>
      <v-card class="pa-2 mt-3 elevation-0">
       
        <a
          href="javascript:void(0);"
          @click="selectTagList()"
          class="black--text"
        >
          <v-icon class="mr-2">mdi-tag-multiple-outline</v-icon>Category
        </a>
      </v-card>

      <addField
        :path="currentTable.tableUrl"
        :additionalAttributes="additionalAttributes"
        :showDialog="openDialog"
        @dialog-closed="openDialog=false"
      >
        <v-card class="pa-2 mt-3 elevation-0">
          <v-icon class="mr-2 mt-n1">mdi-tag-multiple-outline</v-icon>{{language.componentLabel.labelCategory}}
        </v-card>
      </addField>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import addField from "@/commonComponents/addField.vue";
export default {
  data() {
    return { openDialog: false };
  },
  // this.$emit("close-menu");
  components: { addField },
  props: ["currentTable", "selectedRows"],
  computed: {
    additionalAttributes() {
      return {
        function: "listAllTestCategory",
        requestType: "ajax",
        selected: Object.keys(this.selectedRows) /*, 'hideButtons':'true'*/,
        requiredPage: this.currentTable.tableUrl,
      };
    },
  },
};

// function selectTagList(caller,single){
//   selected=[];
//   if(single==false){
//     $('.selectedElements:checked').each(function(){
//       selected.push($(this).val());
//     });
//   }else {
//     selected.push(single);
//   }
//   if (selected.length>0){
//     let options = { 'function':'listAllTestCategory',
//       'requestType':'ajax' ,
//       selected: selected/*, 'hideButtons':'true'*/,
//       requiredPage :location.href };
//     let sentURI = "serve.php?f=testing&f2=configureAndRunTests";
//     let dialogOptions = { width: 550,  height:500};
//     let title="Category";
//     var $returnedDialog = getDialog(sentURI,options,dialogOptions,title,false);
//     var $callingObject = $(caller);
//     var tableID = $callingObject.closest(".tableContent").find(".dataTable").attr("id");
//     $returnedDialog.attr("data-tableID",tableID);
//   }else {
//     showWarn();
//   }
// }

// function showWarn(){
//   var activate = $("#showWarnSelect").show();
//   $("#showWarnSelect").delay(3000).fadeOut('slow');
// }
</script> -->

<template>
  <a class="black--text" @click="getCategories()">
    <v-list-item>
        <v-list-item-title>
          <v-card class="pa-2 mt-3 elevation-0">
            <v-icon class="mr-2 mt-n1">mdi-tag-multiple-outline</v-icon>
            {{ language.componentLabel.labelCategory }}
          </v-card>
          <FormDialog v-model="dialog" :title="language.componentLabel.labelCategory" @save="saveCategories()">
            <template #dialog-content>
              <XSelect
                class="categories"
                v-model="categories"
                :items="allCategories"
                :label="language.componentLabel.labelCategory"
                deletable-chips
                multiple
                combobox
                chips>
              </XSelect>
              <v-spacer></v-spacer>

            </template>
          </FormDialog>
        </v-list-item-title>
    </v-list-item>
  </a>
</template>

<script>
import FormDialog from '@/components/extended/FormDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
// import axios from 'axios';
import testCaseInfoService from "@/js/services/TestCaseInfoService";

export default {
  name: 'selectTagList',
  components: { FormDialog, XSelect },
  props: {
    selectedRows: Object,
  },
  data() {
    return {
      allCategories: [],
      categories: [],
      cornerButtons: [],
      dialog: false,
      testCaseId: Object.keys(this.selectedRows)[0] };
  },
  methods: {
    getCategories() {
      testCaseInfoService.getTestCaseCategories(this.testCaseId, (categories) => {
        this.categories = categories.split(',');
        this.dialog = true;
    });
    },
    saveCategories() {
      this.$emit('refresh');
    }
  },
  created() {
    this.cornerButtons = [{icon: ""}]
    testCaseInfoService.getCategories((allCategories) => {
        this.allCategories = allCategories;
      });
  },
}
</script>
